import BASE_URL from "config/endpoints";
import config from "../config/app";
import encrypt from "./encrypt";

// payload consists of email and last 6 of ssn eg: ({ email: "my-email@gmail.com", str: "123456" })
export default async function authenticate(payload) {
  // console.log("payload", payload);
  // overwrite str payload for encryption before sending
  const cipher = encrypt(payload.str, config.encKey);
  payload.str = cipher;
  // send
  const res = await fetch(`${BASE_URL}/customer/verify`, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,POST,OPTIONS,DELETE,PUT",
    },
    body: JSON.stringify(payload),
    method: "POST",
  });

  if (!res.ok) {
    const err = await res.text();
    throw Error(err);
  }
  const data = await res.json();
  return data;
}
