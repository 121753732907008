import React, { useState, useEffect, useCallback } from "react";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

function MaterialUIPickers({ dateHandler, initialDate, id }) {
  const [selectedDate, setDate] = useState(new Date());

  const initialize = useCallback(() => {
    if (initialDate) {
      setDate(initialDate);
    }
  }, [initialDate]);
  useEffect(() => {
    initialize();
  }, [initialize]);

  const handleDateChange = (newDate) => {
    setDate(newDate);
    dateHandler(newDate);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        fullWidth
        margin="normal"
        id={id}
        label="Select date"
        format="MM/dd/yyyy"
        value={selectedDate}
        onChange={handleDateChange}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
      />
    </MuiPickersUtilsProvider>
  );
}

export default MaterialUIPickers;
