import React from "react";
import { AppBar, makeStyles, Box } from "@material-ui/core";

import { ReactComponent as Logo } from "assets/images/solera-bank-logo.svg";

const useStyles = makeStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
  },
  graphicContainer: {
    paddingLeft: 16,
    height: "100%",
  },
  logo: {
    height: "60px",
    fill: "#fff",
  },
  save: {
    color: "#fff",
    fontSize: 14,
    paddingRight: 19,
  },
}));

const Header = () => {
  const classes = useStyles();

  return (
    <AppBar
      position="static"
      data-component="header"
      className={classes.head}
      elevation={0}
    >
      <Box
        className={classes.graphicContainer}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Logo className={classes.logo} />
      </Box>
    </AppBar>
  );
};

export default Header;
