import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  form: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    maxWidth: "600px",
    [theme.breakpoints.up("md")]: {
      maxWidth: "700px",
    },
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      width: "100%",
      marginBottom: "50px",
      maxWidth: "700px",
    },
  },
  emailContainer: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      marginRight: "30px",
      width: "40%",
    },
  },
  ssnContainer: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "60%",
    },
  },
  buttonContainer: {
    width: "100%",
    position: "relative",
    marginTop: "40px",
    "& button": {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      width: "75%",
      marginTop: 0,
      "& button": {
        width: "inherit",
      },
    },
  },
  ssn: {
    display: "flex",
    "& svg": {
      opacity: 0.6,
      cursor: "pointer",
    },
  },
}));
