import React from "react";
import { Box, Typography } from "@material-ui/core";

import copy from "./copy.json";

export default function Success() {
  return (
    <Box>
      <Box>
        <Typography variant="h6">{copy.heading}</Typography>
      </Box>

      <Box mt={40}>
        <Typography variant="h6">{copy.subheading}</Typography>
      </Box>
      <Box mt={20}>
        <ul>
          {copy.body.map((item, i) => (
            <Box key={i} mb={15}>
              <Box>
                <Typography variant="body1">
                  <li>{item}</li>
                </Typography>
              </Box>
            </Box>
          ))}
        </ul>
      </Box>
      <Box mt={80}>
        <Typography variant="body1">
          <b>{copy.subheading2}</b>
        </Typography>
      </Box>
      <Box mt={30}>
        <Typography variant="body1">
          Email: <a href="mailto: FMV@solerabank.com">FMV@solerabank.com</a>
        </Typography>
      </Box>
      <Box mt={20}>
        <Typography variant="body1">
          Phone: <a href="tel:3039376426">(303) 937-6426</a>
        </Typography>
      </Box>
      <Box mt={20}>
        <Typography variant="body1">
          Hours: Monday - Friday 9am - 5pm MT
        </Typography>
      </Box>
    </Box>
  );
}
