import React from "react";
import { Box, Typography } from "@material-ui/core";

import copy from "./copy.json";
export default function FAQ() {
  return (
    <Box mt={30}>
      {copy.map((item, i) => (
        <Box key={i} mb={40}>
          <Box>
            <Typography variant="h6">{item.question}</Typography>
          </Box>
          <Box mt={4}>
            <Typography
              dangerouslySetInnerHTML={{
                __html: `${item.answer}`,
              }}
            />
          </Box>
        </Box>
      ))}
    </Box>
  );
}
