import { createTheme } from "@material-ui/core/styles";
import WorkSansRegular from "../assets/fonts/WorkSans-Regular.ttf";

export const breakpoints = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
};

const RED = "#E04039";
const DARK_RED = "#BF3832";
const BLUE = "#5D89AB";
const DARK_BLUE = "#22415F";
const LIGHT_BLUE = "#C1E1EE";
const CREAM = "#F4F0E5";
const WARM_BLACK = "#201C1C";
// const GRAY = "#F8F9FA";
const WHITE = "#FFF";

const workSans = {
  fontFamily: "WorkSans",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
    url(${WorkSansRegular}) format('truetype')
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const palette = {
  primary: {
    main: RED,
    dark: DARK_RED,
  },
  secondary: {
    main: BLUE,
    light: LIGHT_BLUE,
    dark: DARK_BLUE,
  },
  info: {
    main: CREAM,
  },
  text: {
    primary: WARM_BLACK,
  },
  background: {
    default: WHITE,
  },
};

const theme = createTheme({
  spacing: 1,
  palette: palette,
  breakpoints: {
    values: {
      ...breakpoints,
    },
  },
  status: {
    danger: "orange",
  },
  typography: {
    fontFamily: "WorkSans",
    h2: {
      fontFamily: "WorkSansSemi",
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [workSans],
      },
    },
    MuiContainer: {
      root: {
        paddingLeft: "16px",
        paddingRight: "16px",
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: "14px",
      },
    },
    MuiButton: {
      sizeLarge: {
        height: 36,
        minWidth: 200,
        [`@media (min-width:${breakpoints.sm}px)`]: {
          minWidth: 300,
        },
      },
      label: {
        fontSize: "14px",
        fontWeight: "500",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.14",
        letterSpacing: "1.25px",
      },
    },
  },
});

theme.typography.h2 = {
  fontSize: 20,
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: 1.2,
  letterSpacing: 0.15,
  color: "#142b2c",
  [theme.breakpoints.up("md")]: {
    fontSize: 40,
  },
};

export default theme;
