import crypto from "crypto-js";

export default function encrypt(value, encryptionKey) {
  try {
    const cipher = crypto.AES.encrypt(
      value.toString(),
      encryptionKey
    ).toString();
    return cipher;
  } catch (e) {
    throw e;
  }
}
