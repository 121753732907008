import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  FormControl,
  Container,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  TextField,
  Button,
} from "@material-ui/core";
import { isEmpty, cloneDeep } from "lodash";

import DatePicker from "components/DatePicker";
import NumberFormatInput from "components/NumberFormatInput";
import ButtonSpinner from "components/ButtonSpinner";

import copy from "./copy.json";
import { useStyles } from "./styles";

const ValueForm = ({ isLoading, data = [], completionHandler, setScreen }) => {
  const mountedRef = useRef(null);
  const [inputs, setInputs] = useState([]);
  const [confirmed, setConfirmed] = useState(false);

  const classes = useStyles();
  useEffect(() => {
    mountedRef.current = true;
    let mounted = mountedRef.current;
    function init() {
      const initialState =
        (Array.isArray(data) &&
          data.map((_) => ({
            marketValue: _.marketValue || "",
            valuationDate: new Date(),
          }))) ||
        [];
      if (!mounted) return;
      setInputs(initialState);
    }

    function resolveCompletion() {
      // if completed, go to the next screen, don't allow resubmission here
      const account1 = data[0];
      const completedAt = account1.completedAt;
      if (completedAt) {
        return mounted && setScreen(3);
      } else {
        return mounted && init();
      }
    }

    resolveCompletion();
    return function cleanup() {
      mounted = false;
    };
  }, [setScreen, data]);

  function handleValueChange({ target }, i) {
    const newInputs = cloneDeep(inputs);
    if (newInputs[i]) {
      newInputs[i][target.name] = target.value;
    } else {
      newInputs[i] = { [target.name]: target.value };
    }
    return setInputs(newInputs);
  }

  function handleDateChange(val, i) {
    const newInputs = cloneDeep(inputs);
    if (newInputs[i]) {
      newInputs[i].valuationDate = val;
    } else {
      newInputs[i] = { valuationDate: val };
    }

    return setInputs(newInputs);
  }

  function handleConfirmation(e) {
    setConfirmed(e.target.checked);
  }

  function handleSubmit(e) {
    e.preventDefault();
    completionHandler(inputs);
  }

  function resolveSubmitDisabled() {
    if (isEmpty(data)) return true;
    if (!confirmed) return true;
    let disabled = false;
    data.forEach((_, index) => {
      if (!inputs[index]) {
        disabled = true;
      } else {
        if (!inputs[index].marketValue) disabled = true;
        if (!inputs[index].valuationDate) disabled = true;
      }
    });
    return disabled;
  }

  const displayName = !isEmpty(data) && data[0].customerFullName;

  return (
    <Container maxWidth="md">
      <Box>
        <Box>
          <Typography variant="h6">Hello, {displayName}</Typography>
        </Box>
        <Box mt={40}>
          <Typography>
            {isEmpty(data)
              ? ""
              : data.length === 1
              ? "Please provide your fair market value at this time."
              : "Please provide your fair market value for all accounts at this time."}
          </Typography>
        </Box>
        <Box>
          <form
            onSubmit={handleSubmit}
            className={classes.formContainer}
            data-testid="value-input-form"
          >
            <Box display="flex" flexWrap="wrap">
              {Array.isArray(data) &&
                data.map((account, i) => (
                  <Box
                    className={`${classes.accountContainer} ${
                      i === 0 ? classes.accountContainer2 : ""
                    }`}
                    key={`${account.trustOrLlcName}-${i}`}
                  >
                    <Box display="flex">
                      {account.trustOrLlcName === "#N/A" ? (
                        <>
                          <Typography>Account number:</Typography>
                          <Box ml={25}>
                            <Typography> {account.accountNumber}</Typography>
                          </Box>
                        </>
                      ) : (
                        <>
                          <Typography>Trust/LLC:</Typography>
                          <Box ml={25}>
                            <Typography> {account.trustOrLlcName}</Typography>
                          </Box>
                        </>
                      )}
                    </Box>
                    <Box display="flex">
                      {account.trustOrLlcName !== "#N/A" && (
                        <>
                          <Typography>Account number:</Typography>
                          <Box ml={25}>
                            <Typography> {account.accountNumber}</Typography>
                          </Box>
                        </>
                      )}
                    </Box>
                    <Box>
                      <Box mt={30}>
                        <FormControl fullWidth>
                          <TextField
                            label="Valuation amount"
                            value={(inputs[i] && inputs[i].marketValue) || ""}
                            autoFocus={i === 0}
                            onChange={(target) => handleValueChange(target, i)}
                            name="marketValue"
                            id={`marketValue${i}`}
                            InputProps={{
                              inputComponent: mountedRef.current
                                ? NumberFormatInput
                                : "input",
                            }}
                            inputProps={{ "data-testid": "valuation-input" }}
                          />
                          <Box mt={15}>
                            <FormHelperText>
                              The total value of the IRA includes any cash in
                              the IRA account, any cash in the LLC or Trust
                              checking account, and the value of all assets or
                              external investments held in the LLC or Trust.
                            </FormHelperText>
                          </Box>
                        </FormControl>
                      </Box>
                      <Box mt={35}>
                        <FormLabel component="legend">
                          {"Valuation date"}
                        </FormLabel>
                        <DatePicker
                          id={`datepicker-birthDate${i}`}
                          initialDate={
                            (inputs[i] && inputs[i].valuationDate) || new Date()
                          }
                          dateHandler={(val) => handleDateChange(val, i)}
                        />
                      </Box>
                    </Box>
                  </Box>
                ))}
            </Box>
            <Box mt={50} position="relative">
              <Box mb={30}>
                <Typography variant="h6">Confirm &amp; Submit</Typography>
              </Box>
              <Box mb={40} className={classes.confirmation}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={confirmed}
                      onChange={handleConfirmation}
                      name="confirm"
                      data-testid="checkbox-submission"
                    />
                  }
                  label={copy.confirm}
                />
              </Box>
              <Box className={classes.buttonContainer}>
                <Button
                  size="large"
                  type="submit"
                  variant="contained"
                  color="primary"
                  data-testid="submit-action"
                  disabled={isLoading || resolveSubmitDisabled()}
                >
                  Save
                </Button>
                {isLoading && <ButtonSpinner />}
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
    </Container>
  );
};

ValueForm.defaultProps = {
  data: [],
};

export default ValueForm;
