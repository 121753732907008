export const VISITED = "visited-fmv";

export function setLocal(key, payload, expiry) {
  try {
    let toSet = { data: payload };
    if (expiry) {
      toSet["expires"] = expiry;
    }
    window.localStorage.setItem(key, JSON.stringify(toSet));
  } catch (_) {}
}

export function removeLocal(key) {
  try {
    window.localStorage.removeItem(key);
  } catch (_) {}
}

export function getLocal(key) {
  let local;
  try {
    local = window.localStorage.getItem(key);
  } catch (_) {}
  if (local) {
    // check expiry
    const obj = JSON.parse(local);
    if (!!obj.expires) {
      const hasExpired = new Date().getTime() > obj.expires;
      if (!hasExpired) {
        return obj.data;
      } else {
        return null;
      }
    }
    return local;
  }
  return null;
}
