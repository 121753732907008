import React, { useState, useEffect } from "react";
import { ThemeProvider } from "@material-ui/core/styles";

import { Container, CssBaseline, Box, Typography } from "@material-ui/core";

import Header from "components/Header";
import Footer from "components/Footer";
import Modal from "components/Modal";
import Welcome from "containers/Welcome";
import ValueForm from "containers/ValueForm";
import Success from "containers/Success";

import theme from "./config/theme";
import authenticate from "util/authenticate";
import updateAccount from "util/updateAccount";

import { setLocal, VISITED } from "util/handleLocalStorage";
import "./App.css";

function checkLoadBehavior() {
  const params = new URLSearchParams(window.location.search);
  const modalOpen = params.get("modal");
  return !!modalOpen;
}

function App() {
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [jwt, setJwt] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [modalContentItem, setModalContentItem] = useState(null);
  const [screenNumber, setScreenNumber] = useState(1);

  useEffect(() => {
    const openModalOnLoad = checkLoadBehavior();
    if (openModalOnLoad) {
      setModalContentItem(1);
    }
    setLocal(VISITED, "true");
  }, []);

  async function verifyAccount(ssn, email) {
    if (!ssn || !email) return;
    // take ssn and query params
    // hit endpoint that verifies account
    // returns account data if verified
    try {
      setLoading(true);
      setError(null);

      const { token, data } = await authenticate({ email, str: ssn });
      console.info("verify:auth", { token, data });

      setData(data); // ids are stored here
      setJwt(token);

      setScreenNumber(2);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  async function saveFairMarketValue(accountsInputs) {
    setLoading(true);
    setError(null);

    try {
      await Promise.all(
        accountsInputs.map((inputs, i) => {
          inputs.value = parseFloat(inputs.marketValue);
          return updateAccount(data[i].id, jwt, inputs);
        })
      );

      setScreenNumber(3);
    } catch (error) {
      console.error("save:fmv", error);
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header />
      <main className="App">
        <Container maxWidth="md">
          {screenNumber === 1 && (
            <Welcome isLoading={isLoading} verifyAccount={verifyAccount} />
          )}
          {screenNumber === 2 && (
            <ValueForm
              completionHandler={saveFairMarketValue}
              data={data}
              isLoading={isLoading}
              setScreen={setScreenNumber}
            />
          )}
          {screenNumber === 3 && <Success />}
          <Box mt={30} minHeight={30}>
            {error && (
              <Typography color="error" data-testid="error-feedback">
                {error.message}
              </Typography>
            )}
          </Box>
        </Container>
      </main>
      <Footer handleChange={setModalContentItem} />
      <Modal
        open={modalContentItem !== null}
        handleClose={() => setModalContentItem(null)}
        contentItem={modalContentItem}
      />
    </ThemeProvider>
  );
}

export default App;
