const isDev = process.env.REACT_APP_ENV !== "production";
const isStage = process.env.REACT_APP_ENV === "staging";
// TODO: fix the appId to be constructed from the package.json file.
const version = "1.0.0";
const appId = `blaze-fair-market@${version}`; // `${process.env.npm_package_name}@${process.env.npm_package_version}`;

const config = {
  appId,
  isDev,
  isStage,
  version,

  encKey: isDev ? "1fAtYu1gFmKLPYTAdnxt" : "1fAtYu1gFmKLPYTAdnxt",

  sentry: {
    dsn: "https://926948b402164dffb0b05d501fd53269@o1025860.ingest.sentry.io/6480498",
    environment: process.env.REACT_APP_ENV,
    release: appId,
  },
};

export default config;
