import BASE_URL from "config/endpoints";

export default async function updateAccount(id, jwt, payload) {
  console.info("update:call", {
    env: process.env.REACT_APP_ENV,
    path: `${BASE_URL}/customer/${id}/update`,
    payload,
    jwt,
  });

  const res = await fetch(`${BASE_URL}/customer/${id}/update`, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,POST,OPTIONS,DELETE,PUT",
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
    method: "POST",
  });

  if (!res.ok) {
    const err = await res.text();
    console.error("update:error", { status: res.status, err });
    throw Error(err);
  }

  const data = await res.json();
  console.info("update:response", { data });
  return data;
}
