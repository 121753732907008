import React from "react";
import { Box } from "@material-ui/core";

import PrivacyNotice1 from "assets/images/PrivacyNotice1.png";
import PrivacyNotice2 from "assets/images/PrivacyNotice2.png";

const Privacy = () => {
  return (
    <Box mt={30}>
      <Box width="100%">
        <img style={{ width: "100%" }} src={PrivacyNotice1} alt="privacy1" />
        <img
          style={{ width: "100%", marginTop: "-30%" }}
          src={PrivacyNotice2}
          alt="privacy2"
        />
      </Box>
    </Box>
  );
};

export default Privacy;
