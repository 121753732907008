import React from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@material-ui/core";

import Contact from "components/Contact";
import FAQ from "components/FAQ";
import Privacy from "components/Privacy";
import ExampleCalc from "components/ExampleCalc";

export default function Modal({ open, handleClose, contentItem }) {
  return (
    <Dialog
      fullWidth={true}
      maxWidth="lg"
      open={open}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogContent>
        <Typography variant="h5">
          {contentItem === 0 && "Contact us"}
          {contentItem === 1 && "FAQ"}
          {contentItem === 2 && "How to calculate your Fair Market Value"}
          {contentItem === 3 && "Privacy policy"}
        </Typography>
        {contentItem === 0 && <Contact />}
        {contentItem === 1 && <FAQ />}
        {contentItem === 2 && <ExampleCalc />}
        {contentItem === 3 && <Privacy />}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
