import React from "react";
import { makeStyles, CircularProgress } from "@material-ui/core";

const useStyles = makeStyles({
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
});

export default function ButtonSpinner() {
  const classes = useStyles();
  return <CircularProgress size={24} className={classes.buttonProgress} />;
}
