import React from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";

import copy from "./copy.json";
import ExampleImage from "assets/images/ExampleCalc.png";

const useStyles = makeStyles((theme) => ({
  image: {
    width: "1200px",
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
}));
export default function ExampleCalc() {
  const classes = useStyles();
  return (
    <Box mt={30}>
      <Typography variant="h6">{copy.body.heading}</Typography>
      <Box mt={20}>
        <ul>
          {copy.body.content.map((item, i) => (
            <Box key={i} mb={15}>
              <Box>
                <Typography variant="body1">
                  <li>{item}</li>
                </Typography>
              </Box>
            </Box>
          ))}
        </ul>
      </Box>
      <Box mt={30}>
        <Typography variant="h6">{copy.subhead}</Typography>
        <Box mt={30}>
          <img
            className={classes.image}
            src={ExampleImage}
            alt="example calculation"
          />
        </Box>
      </Box>
    </Box>
  );
}
