import React, { useState } from "react";
import {
  Box,
  Typography,
  Input,
  InputLabel,
  FormControl,
  Button,
  FormHelperText,
  TextField,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import ButtonSpinner from "components/ButtonSpinner";
import copy from "./copy.json";
import { useStyles } from "./styles";

const SSNInput = ({ label, name, value, handleChange, disabled }) => {
  const [isSSNVisible, handleSSNVisibility] = useState(false);
  const classes = useStyles();
  return (
    <FormControl fullWidth>
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <Input
        value={value || ""}
        disabled={disabled}
        onChange={handleChange}
        name={name}
        type={isSSNVisible ? "text" : "password"}
        id={name}
        inputProps={{ "data-testid": "ssn-input" }}
        endAdornment={
          <Box className={classes.ssn}>
            <Box onClick={() => handleSSNVisibility(!isSSNVisible)}>
              {isSSNVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </Box>
          </Box>
        }
      />
    </FormControl>
  );
};

const Welcome = ({ isLoading = false, verifyAccount }) => {
  const [ssn, setSsn] = useState("");
  const [email, setEmail] = useState("");
  const classes = useStyles();

  function handleSsn(e) {
    e.preventDefault();
    let value = e.target.value;
    value = value.replace(/\D/g, "");
    if (value.length > 6) return;
    setSsn(value);
  }

  function handleEmail(e) {
    setEmail(e.target.value);
  }

  return (
    <Box>
      <Box mb={20}>
        <Typography variant="h6">{copy.heading}</Typography>
      </Box>
      <Box>
        <Typography variant="body1">
          <b>{copy.subheading}</b>
        </Typography>
      </Box>
      <Box my={20}>
        <ul>
          {copy.body.map((item, i) => (
            <Box key={i} mt={15}>
              <Typography variant="body1">
                <li>{item}</li>
              </Typography>
            </Box>
          ))}
        </ul>
      </Box>
      <Box mt={30}>
        <Typography variant="body1">{copy.body2}</Typography>
      </Box>
      <Box mt={60}>
        <Box>
          <Typography variant="body1">{copy.body3}</Typography>
        </Box>
        <Box mt={15}>
          <FormHelperText>
            NOTE: If you have 2 or more IRA accounts with us, you will be asked
            to enter the value of each on the next screen.
          </FormHelperText>
        </Box>
        <Box display="flex" mt={20} mb={20}>
          <form
            autoComplete="on"
            className={classes.form}
            onSubmit={(e) => {
              e.preventDefault();
              verifyAccount(ssn, email);
            }}
          >
            <Box className={classes.inputContainer}>
              <Box className={classes.emailContainer}>
                <FormControl fullWidth>
                  <TextField
                    onChange={handleEmail}
                    label="Email address"
                    type="email"
                    value={email}
                    fullWidth
                    name="email"
                    id="email"
                    autoFocus={true}
                    autoComplete="on"
                    inputProps={{ "data-testid": "email-input" }}
                  />
                </FormControl>
                <FormHelperText>
                  The address associated with your account
                </FormHelperText>
              </Box>
              <Box className={classes.ssnContainer}>
                <SSNInput
                  disabled={isLoading}
                  handleChange={handleSsn}
                  label="Last 6 digits of social security number"
                  name="ssn"
                  value={ssn}
                />
                <FormHelperText>No dashes required</FormHelperText>
              </Box>
            </Box>
            <Box className={classes.buttonContainer} maxWidth={800}>
              <Button
                variant="contained"
                size="large"
                type="submit"
                color="primary"
                fullWidth
                data-testid="verify-action"
                disabled={isLoading || ssn.length < 6}
              >
                Begin
              </Button>
              {isLoading && <ButtonSpinner />}
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default Welcome;
