import React from "react";
import { BottomNavigation, BottomNavigationAction } from "@material-ui/core";

export default function Footer({ handleChange }) {
  return (
    <>
      <BottomNavigation
        showLabels
        onChange={(_, newValue) => handleChange(newValue)}
      >
        <BottomNavigationAction label="Contact us" />
        <BottomNavigationAction label="FAQ" />
        <BottomNavigationAction label="Example calculation" />
        <BottomNavigationAction label="Privacy policy" />
      </BottomNavigation>
    </>
  );
}
