import React from "react";
import { Box, Typography } from "@material-ui/core";

export default function Contact() {
  return (
    <Box mt={30}>
      <Box mb={40}>
        <Box>
          <Typography variant="body1">
            If you have any questions please contact us at:
          </Typography>
        </Box>
        <Box mt={40}>
          <Typography variant="body1">
            Email: <a href="mailto: FMV@solerabank.com">FMV@solerabank.com</a>
          </Typography>
        </Box>
        <Box mt={20}>
          <Typography variant="body1">
            Phone: <a href="tel:3039376426">(303) 937-6426</a>
          </Typography>
        </Box>
        <Box mt={20}>
          <Typography variant="body1">
            Hours: Monday – Friday 9am – 5pm MT
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
