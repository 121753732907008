import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  accountContainer: {
    marginTop: 60,
    marginBottom: 20,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    [theme.breakpoints.up("md")]: {
      width: 400,
    },
  },
  accountContainer2: {
    [theme.breakpoints.up("md")]: {
      marginRight: 80,
    },
  },
  confirmation: {
    "& label": {
      display: "flex !important",
      alignItems: "flex-start !important",
      "& span": {
        fontSize: "14px !important",
      },
    },
  },
  buttonContainer: {
    position: "relative",
    [theme.breakpoints.up("sm")]: {
      width: "300px",
    },
    "& button": {
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "inherit",
      },
    },
  },
}));
